import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - अध्याय ३ रा" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='अध्याय ३ रा' num='03'/>
        <div id="main">
          <section id="content" className="main">
            <p>
            श्री गणेशाय नम: । हे वक्रतुंडा विघ्नहर्ता । तू सुखकर्ता । तू दुःखहर्ता । तू पावतोस शरणागता ॥ १ ॥
क्रोध कारण नाशास । शंकरास प्रत्ययास । स्वतःच्या मुलाच्या मुखकमळास । क्रोधाने छेदले त्यास ॥ २ ॥
क्रोध इतका नसावा । जेणे जीव चिंताक्रांत व्हावा । दुसर्‍यास ताप नसावा । हाच मार्ग अनुसरावा ॥ ३ ॥
भक्ती करावी इतुकी । संतोष होण्या इतुकी । दुसर्‍या आनंद देण्या इतुकी । नको प्रपंचास पोरकी ॥ ४ ॥
इतके असावे नामजपाला । जेणे प्राप्त शांत चित्ताला । महत्व आहे शांतीला । समाधानी वृत्तीला ॥ ५ ॥
प्रयत्नवादी असावे । अल्पसंतुष्ट नसावे । प्रयत्नवादी असावे । परी दुसर्‍याचे न हिरवावे ॥ ६ ॥
दुसर्‍याच्या हिरावण्यात । निश्चित र्‍हास । हेच येते प्रत्ययास । नको ऐशा कृत्यास ॥ ७ ॥
ज्याला वाटे व्हावे मंगल । नको चिंतन अमंगल । आचरण होता मंगल । गाठता मुक्काम मंगल ॥ ८ ॥
नामामुळे मंगल चिंतन । मंगल चिंतने गुरू स्मरण । गुरू स्मरणे गुरू चरण । तेणे घरास मंगल तोरण ॥ ९ ॥
जे मंगलमय झाले । ते गुरूमय झाले । भक्तीने जे जे अर्पिले । ते ते गुरूने स्विकारले ॥१० ॥
असेच गुरू गजानन भोळे । त्यांनी भक्तांस जवळ केले । भक्ताने गांजाचे नवस केले । श्री गुरूंनी ते स्विकारले ॥ ११ ॥
गांजाचा केला नवस । केवढे भक्ताचे साहस । जाणले गुरूंनी भक्तास । त्याच्या शुद्ध मनास ॥ १२ ॥
शेगावी गुरू गजानन असता । गर्दीस काय पुसता ? । मुद्दाम न कधी जमवता । ओढ लागते चित्ता ॥ १३ ॥
गर्दी नाही जमवावी लागत । जैसी दिसे राजकारणात । नेते भाड्याने जमवतात । गुरू नामाने जमवतात ॥ १४ ॥
नेत्यांचे अशुद्ध चित्त । त्यात भर पाडते वित्त । नाना क्लुप्त्या अंगीकृत । ओढण्या आपल्या पक्षात ॥ १५ ॥
गुरूंचे सदा शुद्ध चित्त । तुच्छ लेखतात वित्त । सत्कृत्याच्या भरीत । भक्तीभाव जागृत ॥ १६ ॥
शुद्धतेची बैठक टिकते । नच भाडोत्री टिकते । हेच अनुभवास येते । शुद्धतेत सारे मिळते ॥ १७ ॥
गुरू बोल मौलिक विचार । कोण त्यास भ्रष्ट करणार ? । जो दुसर्‍यास उद्धरणार । तो सदाचारीच असणार ॥ १८ ॥
कितीही प्रसंग बिकट । कळते गुरूची किंमत । श्री गुरू चमत्कृतीने श्रेष्ठ । नेता धन क्लुप्त्यात श्रेष्ठ ॥ १९ ॥
ऐसा नेता टिकणार । जो शुद्ध चित्ताने वागणार । वेदांचे सार हेच सांगणार । चित्त शुद्धीत सारे टिकणार ॥ २० ॥
जगावे व्यवहारापुरते । अंतरी भाव ईश्वरा भेटण्याते । मनाने त्याचे होण्याते । सदा कृपा त्याची असते ॥ २१ ॥
जो ईश्वर प्राप्तीस उत्सुक । तो नामाचे रोपटे लावण्यास उत्सुक । नाम घेता हसतमुख । ईश्वर भेट हसतमुख ॥ २२ ॥
जो नामाची ऐशी बी पेरतो । तो नामाने अविनाशी ठरतो । जरी तो देह रूपाने जातो । परी नामाने चिरंजीव होतो ॥ २३ ॥
नित्य ताजे नवे । शेगावी दिसावे । जेथे महाराज असावे । लीलेनेच नाचावे ॥२४ ॥
बंकटाच्या घरी । ऐन सकाळच्या प्रहरी । दर्शनास नरनारी । परगांवांहुन येणारी ॥ २५ ॥
नाना तर्‍हेचे भक्त जमले । नाना तर्‍हेचे नवस झाले । महाराजांचे दर्शन घेतले । कृतकृत्य झाले ॥ २६ ॥
तर्‍हेवाईक भक्तांचे तर्‍हेवाईक नवस । प्रसन्न करावे गजाननास । आले नवस फेडण्यास । कृपाशिष घेण्यास ॥ २७ ॥
गजाननाचे दास होण्याते । सर्वांनाच वाटते । परी नाही सोपे इतुके । हे अनुभवानेच पटते ॥ २८ ॥
वारंवार परिक्षा घेतात । जे उत्तीर्ण होतात । तेच दास होतात । बाकीचे तसेच रहातात ॥ २९ ॥
गुरू चरण सहज न मिळे । नुसत्या नवसाने न मिळे । स्वतःचे ओतावे लागते सगळे । हे सकळांस न कळे ॥ ३० ॥
आधी व्हावे लागते त्याचे । मग नवस बोलायचे । ऐसे नवस बोलायचे । जे गुरूने स्विकारायचे ॥ ३१ ॥ 
असाच गोसावी काशीचा । अंतर्मनाने महाराजांचा । नवस केला गांजाचा । म्हणे स्विकार करावा त्याचा ॥ ३२ ॥
प्रातःकाळच्या समयाला । काशीचा गोसावी आला । पहातो तो रांगेला । म्हणे कैसे होईल दर्शनाला ॥ ३३ ॥
रांगेत श्रीमंत नरनारी । हा गोसावी भिकारी । कोण त्यास उभा करी । क्षणभर विचार करी ॥ ३४ ॥
भगवी चिंधी डोक्यास । फाटकी लंगोटी नेसण्यास । एक झोळी बगलेस । बसून राहिला कोपर्‍यास ॥ ३५ ॥
दर्शनास गर्दी फार । दर्शन कैसे होणार ? । हाच सतत विचार । गोसाव्यास सतावे फार ॥ ३६ ॥
मुखे होतसे नामस्मरण । गजानन गजानन । होतसे त्याचे चिंतन । म्हणे कैसे घडेल दर्शन ? ॥ ३७ ॥
मी काशीस असताना । ऐकल्या त्याच्या कथा । ओढ लागली माझ्या चित्ता । आलो दर्शना करता ॥ ३८ ॥
काशीस असताना । गांजाचा नवस बोलताना । काही न आले माझ्या मना । कैसे अर्पण करावे ह्यांना ? ॥ ३९ ॥
गांजाचा नवस कळता । लोक मारतील लाथा । म्हणे भ्रष्ट करण्या करता । तू आलास येथे येता ॥ ४० ॥
परी मी बोललो मनाने । नवस केला आवडीने । स्विकारावी बुटी गुरूने । धन्य होईल माझे जीणे ॥ ४१ ॥
जे मला आवडते । त्याचे नवस होते । तेच अर्पण करण्याते । नवसाची पूर्ती होते ॥ ४२ ॥
त्याच्या जे जे मनात । विचार होते चालत । अंतर्ज्ञानाने जाणतात । श्री गुरू साक्षात ॥ ४३ ॥
अहो रांग जरी भली मोठी । परी प्रसन्न झाली गुरू माऊली । गुरूंनी खूण केली । गोसाव्यास ती पटली ॥ ४४ ॥
श्री गुरू कित्येक वेळी । न बोलती मुळी । बोलती ते ऐशा वेळी । जे ठरते योग्य वेळी ॥ ४५ ॥
हा गोसावी मुळचाच पुण्यवान । त्यात करे नामस्मरण । शिल्लक असता पुण्य । चांगल्या कुळी जनन ॥ ४६ ॥
जीवाचा संबंध अवलंबणार । एक एक कर्मावर । पुण्य कर्मावर । पुण्यवान होणार ॥ ४७ ॥
काशीचा गोसावी पुण्यवान । गजानने त्यास ओळखून । आणले बोलावून । एका कोपर्‍यातून ॥ ४८ ॥
गुरूस मनातले कळते । हे तत्व खरे ठरते । अगाध शक्ती जाणते । ह्याचे प्रत्यंतर येते ॥ ४९ ॥ 
गोसाव्याच्या खिशातली । गांजाची बुटी काढली । म्हणे का रे ऐशी लपवली ? । खुण सार्‍यांस पटवली ॥ ५० ॥
गजानन समर्थ ठरले, ठरणार । गोसावीही मुळचाच हुशार । संधीचा फायदा घेतला सत्वर । गुरूस बोलला तत्पर ॥ ५१ ॥
बुटी काढीन एका अटीवर । तुम्ही कधी न करावी दूर । गुरूंनी दिला होकार । केला तिचा स्विकार ॥ ५२ ॥
गुरू नाही भाळला बुटीला । जरी जवळ केले बुटीला । गुरू भाळले भोळ्या भावाला । जेणे गांजाचा स्वीकार केला ॥ ५३ ॥
काहिंना असे वाटते । नवसाने काय होते? । तीर्थ अंगार्‍याने काय होते? । हेच त्यांना कोडे पडते ॥ ५४ ॥
जे वैद्यकीय इलाजाने । नाही बरे होणार दुखणे । कैसे तीर्थ अंगार्‍याने । बरे होईल दुखणे? ॥ ५५ ॥
शंका कुशंका मनात । तर्क वितर्क मनात । काहूर माजते मनात । नाना प्रश्र्न मनात ॥ ५६ ॥
चित्ताची शांती ढळते । विचार चक्र जलद चालते । ऐसे चक्र जलद चालते । गुरू काय करणार तिथे ? ॥ ५७ ॥
गुरू ऐसे नाही वदणार । जेणे शांती ढळणार । गुरू दोषित नसणार । कर्मच दोषित ठरणार ॥ ५८ ॥
जीवनी कर्म प्रधान ठरते । प्रत्येक जीवास करावे लागते । त्याचे फळ भोगावे लागते । कोणी न सुटणार त्याते ॥ ५९ ॥
जैसे ज्याचे चिंतन । तैसे त्याचे आचरण । नको शंकेस स्थान । जेणे धोक्याचे कारण ॥ ६० ॥
शंकेने जी जी कृती । तेथे न होते प्रगती । संशयात्मा विनश्यती । हीच ठरते खरी उक्ती ॥ ६१ ॥
अहो वेदान्त एवढे सांगितले । ज्यांनी ज्यांनी अनुसरले । ते जीवनी धन्य झाले । जीवनाचे सार्थक झाले ॥ ६२ ॥
श्रद्धेने जे जे होते । ते फलदायी ठरते । श्रद्धेवर अवलंबण्याते । बोजे हलके होते ॥६३ ॥
श्रद्धेने टळते गंडांतर । ह्याचे येते प्रत्यंतर । जानराव देशमुखावर । आले एकदा गंडांतर ॥ ६४ ॥
शेगावात जानराव देशमुख । प्रसिद्ध व्यक्ती एक । तापाने पीडला देशमुख । झाली गती मरणोन्मुख ॥ ६५ ॥
ज्वराने जानरावास घेरले । कित्येक दिवस गेले । वैद्यकीय इलाज झाले । परी सारे निकामे ठरले ॥ ६६ ॥
रोग गेला बळावून । जानराव झाला क्षीण । जो होतो जर्जर क्षीण । तेव्हाच गुरू चरणी लीन ॥ ६७ ॥
हेच प्रत्ययास आले । जानरावाचे तसेच झाले । औषधोपचार सरले । आप्तेष्ट विचारात पडले ॥ ६८ ॥
नच काही उरले बळ । जो होतो हतबल । तो जातो गुरू जवळ । टाळण्या संकट सकळ ॥ ६९ ॥
बंकटाच्या घरी । गुरू माऊली वास करी । जावे त्याच्या घरी । आप्तेष्ट विचार करी ॥ ७० ॥
साधुने मनात आणता । त्याचे कृपाशीष मिळता । जानराव वाचेल आता । नको उशीर आता ॥ ७१ ॥
एक बंकटाकडे आला । वृत्तांत सारा वदला । म्हणे प्रार्थना करा गुरूला । द्यावे तीर्थ अंगार्‍याला ॥ ७२ ॥
त्यावर बंकट बोलला । श्रद्धेने घेता तीर्थाला । आराम पडेल त्याला । खात्री देतो तुम्हाला ॥ ७३ ॥
आपल्या वडिलांस वदला । भवानीराम थोर भला । विनवू लागला गुरूला । म्हणे देतो तीर्थ अंगार्‍याला ॥७४ ॥
श्री गुरू ! आम्ही अंधारात चालतो । दृष्यासच सत्य मानतो । अदृष्यास असत्य मानतो । अपयश येता धाव धेतो ॥ ७५ ॥
दावावा प्रकाश सत्वर । भाव सारा श्रद्धेवर । जेणे जानरावाचा ज्वर । जाईल सत्वर ॥ ७६ ॥
तीर्थ दिले आप्तेष्टाला । गेला जानरावाच्या घराला । श्रद्धेने पाजले त्याला । श्रद्धेचा महिमा आला फळाला ॥ ७७ ॥
गजानने वाचवले एका जीवाला । संत टाळती गंडांतराला । याचा प्रत्यय आला । गुरू खर्‍या अर्थी समर्थ म्हणायला ॥ ७८ ॥
जानरावाचा ज्वर बरा केला । तीर्थ अंगार्‍याचा महिमा पटवला । जो तो गजाननाचा झाला । चरण धरू लागला ॥ ७९ ॥
गुरूचरण तीर्थ फळाला । आले जानरावाला । तैसेच येवो तुम्हाला । हीच प्रार्थना गजाननाला ॥ ८० ॥
गजानन नव्हते नुसते वेषधारी । खरेखुरे सक्षात्कारी । जानरावास पटल्यावरी । भंडारा घातला बंकटाघरी ॥ ८१ ॥
भलते सलते प्रश्र्न । नका पुसू विनाकारण । गुरू जवळ जाऊन । व्हाल फजितीस कारण ॥ ८२ ॥
श्रद्धेचा खेळ चालतो । जो श्रद्धेवर विसंबतो । तोच वेळप्रसंगी तरतो । ऐसे गुरू वारंवार सांगतो ॥ ८३ ॥
उगाच घेऊ नका परिक्षा । तुम्ही काय घेणार परिक्षा ? । तो उत्तीर्णच होणार परिक्षा । नको शंका कुशंका ॥ ८४ ॥
तुम्ही काय प्रश्र्न विचारणार? । तुमचे प्रश्र्न क्षुल्लक असणार । निरर्थक असणार । परी उत्तर समर्पक मिळणार ॥ ८५ ॥
प्रश्र्न विचारावे ऐसे । जीवन जगावे कैसे? । सुसंगत होईल कैसे? । भगवंतास काय हवेहवेसे? ॥ ८६ ॥
अंतःकरणाच्या ओढीने । होतात गुरूदर्शने । आकर्षितो तळमळीने । नच व्यवहारी धनाने ॥ ८७ ॥
ऐशी भक्त्ती करावी । जेणे गुरूमुर्ती प्रसन्न व्हावी । हीच अंतरिच्छा असावी । गुरूचरणी व्यक्त करावी ॥ ८८ ॥
गुरू जे अंतरंग जाणणार । ते कैसे हजेरीला भुलणार ? । ते कैसे धनाला भुलणार ? । ते खर्‍या प्रेमाला भुलणार ॥ ८९ ॥
हजेरी नाही महत्वाची । बैठक असावी तळमळीची । ओढ असावी चित्ताची । तेणे वेळ गुरूप्राप्तीची ॥ ९० ॥
जे तळमळीने मिळते । तेच केवळ टिकते । जे मनापासून नसते । ते उघडकीस येते ॥ ९१ ॥
फजितीस कारण होते । गुरूकृपा नष्ट होते । जे असते ते जाते । विठोबाचे जे झाले ते ॥ ९२ ॥
ऐसाच विठोबा घाटोळ । जो राहिला समर्थांजवळ । ढोंग लोभाचे वाढता बळ । गुरूने दूर लोटले तत्काळ ॥ ९३ ॥
जीवनात भाव महत्वाचा । तैसाच बोल अंतरीचा । त्या विठोबा घाटोळाचा । भाव लोभी वृत्तीचा ॥ ९४ ॥
शुद्ध भाव नव्हता साचा । तेणे योग गुरूकृपा नष्टचा । कळस झाला ढोंगीपणाचा । प्रसंग आला फजितीचा ॥ ९५ ॥
महाराजांनी ओळखले त्यास । विठोबा घाटोळ भक्तास । सांगितले कित्येक वेळेस । नको होऊ फजितीस ॥ ९६ ॥
नको अनुसरू ढोंग कधी । करशील एके दिवशी उपाधी । नको करू भ्रष्ट बुद्धि । घालवशील सुवर्णसंधी ॥ ९७ ॥
गुरू स्वभावाने कडक । सोसले प्रसंग अनेक । परी परमार्थात जे बोचक । गुरूस न खपणार एकेक ॥ ९८ ॥
एकदा गुरू निद्रावस्थेस । परगावची मंडळी दर्शनास । हेतु सांगितला विठोबास ।  वशील्याने जवळ केले त्यासी ॥ ९९ ॥ 
वशीला जैसा उपयोगी । तैसाच ठरे निरुपयोगी । खरा सत्पुरुष योगी । कधी वशीला न मागी ॥ १०० ॥
ह्याचेच आले प्रत्यंतर । महाराज रागावले फार । विठोबा घाटोळावर । आला प्रसंग अनावर ॥ १०१ ॥
केवढा अनर्थ घडला । विठोबा कायमचा दुरावला । परी गुरूचा ताप गेला । गुरू उपाधीमुक्त झाला ॥ १०२ ॥
महाराज मुळचेच अलिप्त । कैसे होणार लीप्त? । व्यवहारात नि उपाधीत । जे सर्व जन असतात ॥ १०३ ॥
महाराज उपाध्या टाळतात । भाव अंतरीचा जाणतात । जे खरे गुरू असतात । ते ऐसेच वागतात ॥ १०४ ॥
ऐसे समर्थ गजानन । चमत्कृती विलक्षण । थक्क होती जन । सहज धरती गुरुचरण ॥ १०५ ॥
गणामहाराज सांगे । जो शुद्ध अंतरंगे वागे । तेणे न होणार वावगे । होईल त्याच्या मनाजोगे ॥ १०६ ॥
श्रद्धा शुद्धतेचा अभाव । तैशा कृतीस होता वाव । तेणे गुरूप्राप्ती अभाव । गुरूकृपा अभाव ॥ १०७ ॥
तळमळ श्रद्धा जाणून । गुरू करती भक्त रक्षण । शुद्ध अंतरंगाविण । न लाभे गुरू चरण ॥ १०८ ॥
॥ शुभं भवतु ॥
॥ श्री गणपती सरस्वतीर्पणमस्तु ॥
॥ इति श्रीगुरूगजाननलीला ग्रंथस्य तृतीयोऽध्यायः संपूर्णः ॥
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
